import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Body from "./components/Body/Body";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import About from "./components/About/About";
import Signup from "./components/MatrimonyUserAuth/Signup";
import Login from "./components/MatrimonyUserAuth/Login";
import Register from "./components/Register/MatrimonyRegister";
import MatrimonyListUser from "./components/MatrimonyListUser/MatrimonyListUser";
import MatrimonyUserDetail from "./components/MatrimonyUserDetail/MatrimonyUserDetail";
import MatrimonyRegister from "./components/Register/MatrimonyRegister";
import Business from "./components/Business/Business";
import MatrimonyUserProfile from "./components/MatrimonyUserProfile/MatrimonyUserProfile";
import BusinessRegister from "./components/BusinessRegister/BusinessRegister";
import Event from "./components/Event/Event";
import MatrimonyUserUnsubscribed from "./components/MatrimonyUserProfile/MatrimonyUserUnsubscribed";
import UseBusinessDetails from "./utils/useBusinessDetails";
import BusinessDetail from "./components/Business/BusinessDetail";
import Subscription from "./components/Subscription/Subscription";
import BusinessDashboard from "./components/BusinessDashboard/BusinessDashboard";
import Proposals from "./components/Proposals/Proposals";
import MatrimonyDashboard from "./components/MatrimonyDashboard/MatrimonyDashboard";
import BusinessDashboard from "./components/BusinessDashboard/BusinessDashboard";
import Home from "./components/About/Home";
import { ProtectedImageProvider } from "./ProtectedImageContent";

const root = createRoot(document.getElementById("root"))

const App = () => {
    return (
        <>
        
        <ProtectedImageProvider>
            <Navbar />
            <Outlet />
            <Footer />
        </ProtectedImageProvider>
        
        </>
    )
}


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [

            {
                path: "/",
                element: <Home />
            },
            {
                path: "/about",
                element: <About />
            },
            {
                path: "/matrimony/signup",
                element: <Signup />
            },
            {
                path: "/matrimony/login",
                element: <Login />
            },
            {
                path: "/matrimony/listusers",
                element: <MatrimonyListUser />
            },
            {
                path: "/matrimony/userdetail",
                element: <MatrimonyUserDetail />
            },
            {
                path: "/matrimony/register",
                element: <MatrimonyRegister />
            },
            {
                path: "/business",
                element: <Business />
            },
            {
                path: "/matrimony/userprofile",
                element: <MatrimonyRegister />
            },
            {
                path: "/business/register",
                element: <BusinessRegister />
            },
            {
                path: "/event",
                element: <Event />
            },
            {
                path: "/matrimony/unsub",
                element: <MatrimonyUserUnsubscribed />
            },
            {
                path: "/business/profile/:id",
                element: <BusinessDetail />
            },
            {
                path: "/subscription",
                element: <Subscription />
            },
            {
                path: "/matrimony/register",
                element: <MatrimonyRegister />
            },
            {
                path: "/matrimony/user/:id",
                element: <MatrimonyUserDetail />
            },
            {
                path : "/businesss/dashboard",
                element: <BusinessDashboard />
            },
            {
                path : "/matrimony/interest",
                element: <Proposals />
            },
            {
                path : "/matrimony/dashboard",
                element: <MatrimonyDashboard />
            },
            {
                path : "/business/dashboard",
                element : <BusinessDashboard />
            }
            
        ]

    }
])


root.render(<RouterProvider router={router}/>)




