import React from 'react';
import { useEffect, useState } from 'react';
import { DOMAIN_NAME, REJECT_PROPOSAL, ACCEPT_PROPOSAL, MATRIMONY_SHOW_INTEREST } from '../../Constant';
import LoadingDetail from "../UX/LoadingDetail";
import { Link } from 'react-router-dom';


function RejectProposalButton({ interestedUserId }) {
    const handleRejectProposal = async () => {

      try {
            const token = localStorage.getItem("token");
            const response = await fetch(DOMAIN_NAME + REJECT_PROPOSAL + `${interestedUserId}`, {
                method: 'POST',
                headers: {
                   Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
            }
          });

            if (response.ok) {
                alert('Rejected Sucessfully')
                window.location.reload()
            }
            else {
                alert('Internal Server error')
            }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    return (
      <button className="btn" onClick={handleRejectProposal}>
        Reject
      </button>
    );
}

function AcceptProposalButton({ interestedUserId }) {
    const handleAcceptProposal = async () => {

      try {
            const token = localStorage.getItem("token");
            const response = await fetch(DOMAIN_NAME + ACCEPT_PROPOSAL + `${interestedUserId}`, {
                method: 'POST',
                headers: {
                   Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
            }
          });

            if (response.ok) {
                alert('Accepted Sucessfully')
                window.location.reload()
            }
            else {
                alert('Internal Server error')
            }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    return (
      <button className="btn" onClick={handleAcceptProposal}>
        Accept
      </button>
    );
}

const Proposals = () => {

    const [propasals, setProposals] = useState([])
    console.log(propasals)
    useEffect(() => {
        try {
            const fetchInterest = async () => {
                try {
                  const token = localStorage.getItem("token");
                  const response = await fetch(DOMAIN_NAME + MATRIMONY_SHOW_INTEREST , {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization": `Bearer ${token}`, // Adding the auth token to headers
                    }});

                  if (response.ok) {
                    const intereset = await response.json();
                    setProposals(intereset?.data);
                    
                  } else {
                    if (!response.ok && response.status == 401) {
                      alert('Please login to see notification');
                      window.location.href = '/matrimony/register';
                    }
                    else if (!response.ok) {
                        alert()
                        const data =  await response.json();
                        if (data?.message == "No proposals found") {
                            setProposals("No proposol Yet")
                        } 
                      } else {
                        alert('Session Token Error Login agian')
                      }          
                  }                                  
                } catch (err) {
                  console.log(err)
                }
              };
          
            fetchInterest();
          
        } catch (e) {
            console.log(e)
        }
    }, []) 


    return (
        propasals == 'No proposol Yet' ? (
        <div className="p-[2%] flex justify-center min-h-screen bg-cover bg-main-color">
          <h1 className="font-bold">No Interest Shown by anyone Yet :(</h1>
        </div>
      
      ) : 
        propasals.length == 0 ? <LoadingDetail /> :
        (
            <div className="container mx-auto p-4 space-y-4">
      {propasals.map((proposal) => (
        <div
          key={proposal.id}
          className="border border-gray-300 rounded-lg shadow-md p-6 md:p-8 flex flex-col md:flex-row items-start md:items-center justify-between transition-all transform hover:scale-105 hover:shadow-lg"
        >
          <div className="mb-4 md:mb-0 flex-grow">
            <Link
              to={`/matrimony/user/${proposal.interest_shown_user}`}
              className="text-lg md:text-xl font-semibold text-blue-500 hover:text-blue-700"
            >
              <h1>{proposal.interested_user_name} has shown interest in you</h1>
              
            </Link>
            <p>By accepting Your number will be displayed to the interested user.</p>
          </div>
          <div className="flex space-x-4">
            <Link>
              <AcceptProposalButton interestedUserId={proposal.interest_shown_user} />
            </Link>
            <Link>
              <RejectProposalButton interestedUserId={proposal.interest_shown_user} />
            </Link>
          </div>
        </div>
      ))}
    </div>
          )
  )
}

export default Proposals


{/* <div>
              {propasals.map((proposal) => (
                <div key={proposal.id} style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
                  <div>
                    <Link to={"/matrimony/user/" + proposal.interest_shown_user} >
                    <h1>{proposal.interested_user_name} has shown intereset in you</h1>
                    </Link>
                  </div>
                  <div>
                    <Link>
                      <AcceptProposalButton interestedUserId={proposal.interest_shown_user} />
                    </Link>
                    <Link>
                      <RejectProposalButton interestedUserId={proposal.interest_shown_user} />
                    </Link>
                  </div>
                </div>
              ))}
            </div> */}