import React, { createContext, useContext, useEffect } from 'react';

const ProtectedImageContext = createContext();

export const ProtectedImageProvider = ({ children }) => {
  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();
    
    // Global event listeners
    const setupProtection = () => {
      document.addEventListener('contextmenu', preventDefault);
      document.addEventListener('keydown', (e) => {
        if (
          (e.ctrlKey && ['s', 'p', 'c'].includes(e.key.toLowerCase())) ||
          e.key === 'PrintScreen'
        ) {
          preventDefault(e);
        }
      });
      document.addEventListener('dragstart', preventDefault);
      document.addEventListener('selectstart', preventDefault);
    };

    setupProtection();

    // Cleanup
    return () => {
      document.removeEventListener('contextmenu', preventDefault);
      document.removeEventListener('keydown', preventDefault);
      document.removeEventListener('dragstart', preventDefault);
      document.removeEventListener('selectstart', preventDefault);
    };
  }, []);

  return (
    <ProtectedImageContext.Provider value={{}}>
      {children}
    </ProtectedImageContext.Provider>
  );
};

// components/common/ProtectedImage/ProtectedImage.js
export const ProtectedImage = ({ src, alt, className, ...props }) => {
  return (
    <div className="relative">
      <img
        src={src}
        alt={alt}
        className={`pointer-events-none select-none ${className || ''}`}
        {...props}
        style={{
          WebkitUserSelect: 'none',
          WebkitTouchCallout: 'none',
          ...props.style,
        }}
        onContextMenu={(e) => e.preventDefault()}
        draggable={false}
      />
      <div className="absolute inset-0 bg-transparent" />
    </div>
  );
};
